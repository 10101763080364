<template>
        <div class="view">
          <v-breadcrumbs>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
              >Home</v-breadcrumbs-item
            >
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'productSpecsElement', params: {} }">
            Product Specs Element
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :to="{ name: 'productSpecsElementView', params: {} }">
              View Product Specs Element
            </v-breadcrumbs-item>
          </v-breadcrumbs>

          <v-card v-if="loaded">
            <v-card-title>
              View Product Specs Element
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    
          <tr v-if="productSpecsElement.productSpecs">
            <td max-width="35%" class="text-subtitle-1">Product Specs:</td>
            <td>
              {{ getRelationText(productSpecsElement.productSpecs, "productSpecs", "product") }}
            </td>
          </tr>
          
          <tr v-if="productSpecsElement.element">
            <td max-width="35%" class="text-subtitle-1">Element:</td>
            <td>
              {{ getRelationText(productSpecsElement.element, "element", "element_name") }}
            </td>
          </tr>
          
          <tr v-if="productSpecsElement.minPercent">
            <td max-width="35%" class="text-subtitle-1">Min Percent:</td>
            <td>{{ productSpecsElement.minPercent }}</td>
          </tr>
          
          <tr v-if="productSpecsElement.maxPercent">
            <td max-width="35%" class="text-subtitle-1">Max Percent:</td>
            <td>{{ productSpecsElement.maxPercent }}</td>
          </tr>
          
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-space-between ma-5">
            <v-btn @click="goBack">Back</v-btn>
            <v-btn @click="edit(id)" color="primary">Edit</v-btn>
            <span></span>
          </div>
      </div>
    </template>

    <script>
    import { getProductSpecsElement } from "@/services/productSpecsElement";
    
              import { getAllProductSpecs } from "@/services/productSpecs";
            
              import { getAllLabElements } from "@/services/labElement";
            

    export default {
      data() {
        return {
            id: '',
            productSpecsElement: {},
            loaded: false,
            relations: {}
        }
      },
      methods : {
        goBack() {
          this.$router.go(-1);
        },
        edit(id) {
          this.$router.push({ name: "productSpecsElementEdit", params: { id: id }});
        },
        setInstance() {
          this.setDependencies().then( () => {
            getProductSpecsElement(this.id)
            .then((response) => {
              this.productSpecsElement = response.data;
              this.loaded = true;
            })
            .catch( () => {
              this.addErrorMessage("Error loading productSpecsElement");
              this.loaded = true;
            });
          })
        },
        async setDependencies() {
          
            const params_productSpecs = {
              pageIndex: 1,
              pageSize: 100
            };
            await getAllProductSpecs(params_productSpecs)
              .then(response => {
                this.relations.productSpecs = response.data.content;
                return this.relations.productSpecs;
              });
            
            const params_element = {
              pageIndex: 1,
              pageSize: 100
            };
            await getAllLabElements(params_element)
              .then(response => {
                this.relations.element = response.data.content;
                return this.relations.element;
              });
            
        },
        getRelationText(id, relationProperty, relationAttribute) {
          const relationList = this.relations[relationProperty];
          if (relationList) {
            const relationObj = relationList.find(r => r.id == id);
            return relationObj[relationAttribute];
          } else {
            return "";
          }
        }
      },
      created() {
        this.id = this.$route.params.id;
        this.setInstance();
        this.$emit("showParent", false);
      }
    }
    </script>
    