<template>
      <div class="productSpecsElementEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'productSpecsElement', params: {} }">
          Product Specs Element
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'productSpecsElementEdit', params:{id: id} }">
            Edit Product Specs Element
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Product Specs Element</v-card-title>
          <v-card-text>
            <productSpecsElement-form :id="id"></productSpecsElement-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import ProductSpecsElementForm from './ProductSpecsElementForm.vue';

    export default {
      name: 'productSpecsElement',
      data () {
        return {
          id: null
        }
      },
      components: {
        ProductSpecsElementForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    